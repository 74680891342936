var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "promotion-form" }, [
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.code")))
          ]),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "w-full",
            attrs: {
              name: "code",
              danger: _vm.errorCode && _vm.errors.has("code"),
              "danger-text": _vm.$t("shops.errors.code_is_required")
            },
            model: {
              value: _vm.promotion.code,
              callback: function($$v) {
                _vm.$set(_vm.promotion, "code", $$v)
              },
              expression: "promotion.code"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.type")))
          ]),
          _c("v-select", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "w-full select-large",
            class: {
              "danger-border-select":
                _vm.errorDiscountType && _vm.errors.has("discount_type")
            },
            attrs: {
              options: _vm.discountTypes,
              value: _vm.promotion.type,
              name: "discount_type",
              placeholder: _vm.$t("shops.select_discount_type"),
              "danger-text": _vm.$t("shops.errors.discount_type_is_required"),
              danger: _vm.errorDiscountType && _vm.errors.has("discount_type")
            },
            on: {
              input: function(discountType) {
                return _vm.selectDiscount(discountType)
              }
            },
            model: {
              value: _vm.discountName,
              callback: function($$v) {
                _vm.discountName = $$v
              },
              expression: "discountName"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/8 mb-5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(" " + _vm._s(_vm.$t("shops.active")) + " ")
          ]),
          _c("vs-switch", {
            staticClass: "mt-3",
            attrs: { type: "boolean" },
            model: {
              value: _vm.promotion.active,
              callback: function($$v) {
                _vm.$set(_vm.promotion, "active", $$v)
              },
              expression: "promotion.active"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/8" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(" " + _vm._s(_vm.$t("shops.amount")) + " ")
          ]),
          _c("vs-input-number", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            attrs: {
              type: "number",
              name: "amount",
              danger: _vm.errorAmount && _vm.errors.has("amount"),
              "danger-text": _vm.$t("shops.errors.amount_is_required")
            },
            model: {
              value: _vm.promotion.amount,
              callback: function($$v) {
                _vm.$set(_vm.promotion, "amount", $$v)
              },
              expression: "promotion.amount"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/8 mb-5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(" " + _vm._s(_vm.$t("shops.units")) + " ")
          ]),
          _c("vs-input-number", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            attrs: {
              type: "number",
              name: "quantity",
              danger: _vm.errorQuantity && _vm.errors.has("quantity"),
              "danger-text": _vm.$t("shops.errors.units_is_required")
            },
            model: {
              value: _vm.promotion.quantity,
              callback: function($$v) {
                _vm.$set(_vm.promotion, "quantity", $$v)
              },
              expression: "promotion.quantity"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "vx-col w-1/8 mb-5" }, [
        _c("label", { staticClass: "vs-input--label" }, [
          _vm._v(" " + _vm._s(_vm.$t("shops.available_quantity")) + " ")
        ]),
        _c("h3", { staticClass: "text-center" }, [
          _vm._v(_vm._s(_vm.promotion.available_quantity))
        ])
      ])
    ]),
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.from_date")))
          ]),
          _c("datepicker", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            class: {
              "danger-border": _vm.errorFromDate && _vm.errors.has("from_date")
            },
            attrs: {
              inline: true,
              mondayFirst: true,
              placeholder: "01/01/1970",
              danger: _vm.errorFromDate,
              "danger-text": _vm.$t("shops.errors.from_date_is_required"),
              name: "from_date"
            },
            model: {
              value: _vm.promotion.from_date,
              callback: function($$v) {
                _vm.$set(_vm.promotion, "from_date", $$v)
              },
              expression: "promotion.from_date"
            }
          }),
          _c(
            "div",
            {
              staticClass:
                "con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            },
            [
              _vm.errorFromDate && _vm.errors.has("from_date")
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("shops.errors.from_date_is_required")))
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(" " + _vm._s(_vm.$t("shops.to_date")) + " ")
          ]),
          _c("datepicker", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            class: {
              "danger-border": _vm.errorToDate && _vm.errors.has("to_date")
            },
            attrs: {
              inline: true,
              mondayFirst: true,
              placeholder: "01/01/1970",
              danger: _vm.errorToDate,
              "danger-text": _vm.$t("shops.errors.to_date_is_required"),
              name: "to_date"
            },
            model: {
              value: _vm.promotion.to_date,
              callback: function($$v) {
                _vm.$set(_vm.promotion, "to_date", $$v)
              },
              expression: "promotion.to_date"
            }
          }),
          _c(
            "div",
            {
              staticClass:
                "con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            },
            [
              _vm.errorToDate && _vm.errors.has("to_date")
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("shops.errors.to_date_is_required")))
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/8" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.needs_login")))
          ]),
          _c("vs-switch", {
            staticClass: "mt-3",
            attrs: { name: _vm.$t("shops.needs_login"), type: "boolean" },
            model: {
              value: _vm.promotion.needs_login,
              callback: function($$v) {
                _vm.$set(_vm.promotion, "needs_login", $$v)
              },
              expression: "promotion.needs_login"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "vx-col w-1/8" }, [
        _c("label", { staticClass: "vs-input--label" }, [
          _vm._v(_vm._s(_vm.$t("shops.max_applications_for_user")))
        ]),
        _c(
          "div",
          { staticClass: "w-3/4" },
          [
            _c("vs-input-number", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: {
                name: "maxQuantityUser",
                danger:
                  _vm.errorMaxQuantityUser && _vm.errors.has("maxQuantityUser"),
                "danger-text": _vm.$t(
                  "shops.errors.max_applications_for_user_is_required"
                )
              },
              model: {
                value: _vm.promotion.max_qty_user,
                callback: function($$v) {
                  _vm.$set(_vm.promotion, "max_qty_user", $$v)
                },
                expression: "promotion.max_qty_user"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "vx-row mb-6" },
      _vm._l(_vm.languages(), function(language, index) {
        return _c(
          "div",
          { key: index, staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.name_" + language)))
            ]),
            _vm.langRequired(language)
              ? _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "w-full",
                  attrs: {
                    name: "promotionName",
                    danger:
                      _vm.errorPromotionName && _vm.errors.has("promotionName"),
                    "danger-text": _vm.$t(
                      "shops.errors.promotion_name_is_required"
                    )
                  },
                  model: {
                    value: _vm.promotion.name,
                    callback: function($$v) {
                      _vm.$set(_vm.promotion, "name", $$v)
                    },
                    expression: "promotion.name"
                  }
                })
              : _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "promotionName" + language },
                  model: {
                    value: _vm.promotion.translations[language].name,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.promotion.translations[language],
                        "name",
                        $$v
                      )
                    },
                    expression: "promotion.translations[language].name"
                  }
                })
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "vx-row mb-6" },
      _vm._l(_vm.languages(), function(language, index) {
        return _c(
          "div",
          { key: index, staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.description_" + language)))
            ]),
            _vm.langRequired(language)
              ? _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "promotionDescription" },
                  model: {
                    value: _vm.promotion.description,
                    callback: function($$v) {
                      _vm.$set(_vm.promotion, "description", $$v)
                    },
                    expression: "promotion.description"
                  }
                })
              : _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "promotionDescription" + language },
                  model: {
                    value: _vm.promotion.translations[language].description,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.promotion.translations[language],
                        "description",
                        $$v
                      )
                    },
                    expression: "promotion.translations[language].description"
                  }
                })
          ],
          1
        )
      }),
      0
    ),
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.product")))
          ]),
          _c("v-select", {
            staticClass: "w-full select-large select",
            attrs: {
              options: _vm.allowedProducts.map(function(product) {
                return { label: product.name, value: product.id }
              }),
              value: _vm.productSelected.id,
              name: "promotionProduct",
              placeholder: _vm.$t("shops.select_product")
            },
            on: {
              input: function(productSelected) {
                return _vm.selectProduct(productSelected)
              }
            },
            model: {
              value: _vm.productSelected.name,
              callback: function($$v) {
                _vm.$set(_vm.productSelected, "name", $$v)
              },
              expression: "productSelected.name"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.participant")))
          ]),
          _c("v-select", {
            staticClass: "w-full select-large select",
            attrs: {
              options: _vm.participants.map(function(participant) {
                return { label: participant.name, value: participant.id }
              }),
              value: _vm.participantSelected.id,
              name: "promotionParticipant",
              placeholder: _vm.$t("shops.select_participant")
            },
            on: {
              input: function(participantSelected) {
                return _vm.selectParticipant(participantSelected)
              }
            },
            model: {
              value: _vm.participantSelected.name,
              callback: function($$v) {
                _vm.$set(_vm.participantSelected, "name", $$v)
              },
              expression: "participantSelected.name"
            }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "vx-col 1/3" },
      [
        _c(
          "vs-button",
          {
            staticClass: "mt-base",
            attrs: { type: "filled" },
            on: {
              click: function($event) {
                return _vm.validateAndSubmit()
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("shops.save")))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }