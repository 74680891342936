<template>
  <div class="promotion-form">
    <div class="vx-row mb-6">
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.code") }}</label>
        <vs-input
          class="w-full"
          v-model="promotion.code"
          v-validate="'required'"
          name="code"
          :danger="errorCode && errors.has('code')"
          :danger-text="$t('shops.errors.code_is_required')"
        />
      </div>
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.type") }}</label>
        <v-select
          :options="discountTypes"
          @input="(discountType) => selectDiscount(discountType)"
          :value="promotion.type"
          v-model="discountName"
          class="w-full select-large"
          :class="{
            'danger-border-select': errorDiscountType && errors.has('discount_type')
          }"
          name="discount_type"
          :placeholder="$t('shops.select_discount_type')"
          v-validate="'required'"
          :danger-text="$t('shops.errors.discount_type_is_required')"
          :danger="errorDiscountType && errors.has('discount_type')"
        />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/8 mb-5">
        <label class="vs-input--label">
          {{ $t("shops.active") }}
        </label>
        <vs-switch class="mt-3" v-model="promotion.active" type="boolean" />
      </div>
      <div class="vx-col w-1/8">
        <label class="vs-input--label">
          {{ $t("shops.amount") }}
        </label>
        <vs-input-number
          type="number"
          v-model="promotion.amount"
          name="amount"
          v-validate="'required'"
          :danger="errorAmount && errors.has('amount')"
          :danger-text="$t('shops.errors.amount_is_required')"
        />
      </div>
      <div class="vx-col w-1/8 mb-5">
        <label class="vs-input--label">
          {{ $t("shops.units") }}
        </label>
        <vs-input-number
          v-model="promotion.quantity"
          type="number"
          name="quantity"
          v-validate="'required'"
          :danger="errorQuantity && errors.has('quantity')"
          :danger-text="$t('shops.errors.units_is_required')"
        />
      </div>
      <div class="vx-col w-1/8 mb-5">
        <label class="vs-input--label">
          {{ $t("shops.available_quantity") }}
        </label>
        <h3 class="text-center">{{ promotion.available_quantity }}</h3>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/3">
        <label class="vs-input--label">{{ $t("shops.from_date") }}</label>
        <datepicker
          v-bind:class="{'danger-border': errorFromDate && errors.has('from_date')}"
          :inline="true"
          :mondayFirst="true"
          placeholder="01/01/1970"
          v-validate="'required'"
          :danger="errorFromDate"
          :danger-text="$t('shops.errors.from_date_is_required')"
          v-model="promotion.from_date"
          name="from_date" />
        <div class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to">
          <span v-if="errorFromDate && errors.has('from_date')">{{$t("shops.errors.from_date_is_required")}}</span>
        </div>
      </div>
      <div class="vx-col w-1/3">
        <label class="vs-input--label">
          {{ $t("shops.to_date") }}
        </label>
        <datepicker
          v-bind:class="{'danger-border': errorToDate && errors.has('to_date')}"
          :inline="true"
          :mondayFirst="true"
          placeholder="01/01/1970"
          v-validate="'required'"
          :danger="errorToDate"
          :danger-text="$t('shops.errors.to_date_is_required')"
          v-model="promotion.to_date"
          name="to_date" />
        <div class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to">
          <span v-if="errorToDate && errors.has('to_date')">{{ $t("shops.errors.to_date_is_required") }}</span>
        </div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/8">
        <label class="vs-input--label">{{ $t("shops.needs_login") }}</label>
        <vs-switch class="mt-3" v-model="promotion.needs_login" :name="$t('shops.needs_login')" type="boolean" />
      </div>
      <div class="vx-col w-1/8">
        <label class="vs-input--label">{{ $t("shops.max_applications_for_user") }}</label>
        <div class="w-3/4">
          <vs-input-number
            v-model="promotion.max_qty_user"
            name="maxQuantityUser"
            v-validate="'required'"
            :danger="errorMaxQuantityUser && errors.has('maxQuantityUser')"
            :danger-text="$t('shops.errors.max_applications_for_user_is_required')"
          />
        </div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div v-for="(language, index) in languages()" :key="index" class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t(`shops.name_${language}`) }}</label>
        <vs-input v-if="langRequired(language)"
          v-model="promotion.name"
          v-validate="'required'"
          class="w-full"
          name="promotionName"
          :danger="errorPromotionName && errors.has('promotionName')"
          :danger-text="$t('shops.errors.promotion_name_is_required')"
        />
        <vs-input v-else v-model="promotion.translations[language].name" class="w-full" :name="`promotionName${language}`" />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div v-for="(language, index) in languages()" :key="index" class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t(`shops.description_${language}`) }}</label>
        <vs-input v-if="langRequired(language)" class="w-full" v-model="promotion.description" name="promotionDescription" />
        <vs-input v-else class="w-full" v-model="promotion.translations[language].description" :name="`promotionDescription${language}`" />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.product") }}</label>
        <v-select
          :options="allowedProducts.map((product) => ({ label: product.name, value: product.id }))"
          @input="(productSelected) => selectProduct(productSelected)"
          class="w-full select-large select"
          v-model="productSelected.name"
          :value="productSelected.id"
          name="promotionProduct"
          :placeholder="$t('shops.select_product')" />
      </div>
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.participant") }}</label>
        <v-select
          :options="participants.map((participant) => ({ label: participant.name, value: participant.id }))"
          @input="(participantSelected) => selectParticipant(participantSelected)"
          class="w-full select-large select"
          v-model="participantSelected.name"
          :value="participantSelected.id"
          name="promotionParticipant"
          :placeholder="$t('shops.select_participant')" />
      </div>
    </div>
    <div class="vx-col 1/3">
      <vs-button @click="validateAndSubmit()" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";

export default {
  name: "PromotionForm",
  components: {
    Datepicker
  },
  mixins: [ notifications, shopLanguages ],
  props: {
    value: { type: Object, default: null },
    onlyForm: { type: Boolean, default: false },
    dialogProduct: { type: Boolean, default: false },
    dialogParticipant: { type: Boolean, default: false },
  },
  data() {
    return {
      productSelected: {},
      participantSelected: {},
      discountName: "",
      errorCode: false,
      errorPromotionName: false,
      errorFromDate: false,
      errorToDate: false,
      errorAmount: false,
      errorType: false,
      errorQuantity: false,
      errorMaxQuantityUser: false,
      errorDiscountType: false,
      promotion: {}
    };
  },
  computed: {
    ...mapGetters("shops/products", {
      products: "getAll"
    }),
    ...mapGetters("shops/participants", {
      participants: "getAll"
    }),
    discountTypes(){
      return [{label: "%", value: "percent"}, {label: i18n.t("shops.amount"), value: "amount"}]
    },
    allowedProducts() {
      return this.products.filter((p) => p.type.code === 'ticket')
    }
  },
  methods: {
    validateAndSubmit() {
      const payload = { ...this.languages().reduce((carry, lang) => {
          carry[lang] = this.promotion.translations[lang]
          return carry
        }, {}), ...this.promotion}
      
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('submit', payload);
        } else {
          this.notifyWarning(i18n.t("shops.errors.warning"), i18n.t("shops.errors.promotion_validation_failed"));
          if (!this.promotion.code) this.errorCode = true;
          if (!this.promotion.name) this.errorPromotionName = true;
          if (!this.promotion.from_date) this.errorFromDate = true;
          if (!this.promotion.to_date) this.errorToDate = true;
          if (!this.promotion.amount) this.errorAmount = true;
          if (!this.promotion.type) this.errorType = true;
          if (!this.promotion.quantity) this.errorQuantity = true;
          if (!this.promotion.max_qty_user) this.errorMaxQuantityUser = true;
          if (!this.promotion.type) this.errorDiscountType = true;
        }
      });
    },
    selectProduct(productSelected) {
      this.productSelected = productSelected.label
      this.promotion.product_id = productSelected.value
    },
    selectParticipant(participantSelected) {
      this.participantSelected = participantSelected.label
      this.promotion.participant_id = participantSelected.value
    },
    selectDiscount(discountType) {
      this.discountName = discountType.label
      this.promotion.type = discountType.value
    },
  },
  created() {
    this.promotion = JSON.parse(JSON.stringify(this.value))

    if(this.promotion.type != ""){
      this.discountTypes.forEach(discount => {
        if(discount.value === this.promotion.type)
          this.discountName = discount.label
      })
    }
    if(this.promotion.product && this.promotion.product.name != ""){
      this.products.forEach(product => {
        if(product.name === this.promotion.product.name)
          this.productSelected = product
      })
    }
    if(this.promotion.participant && this.promotion.participant.name != ""){
      this.participants.forEach(participant => {
        if(participant.name === this.promotion.participant.name)
          this.participantSelected = participant
      })
    }
  }
};
</script>
