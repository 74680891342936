<template>
  <div class="promotions-view">
    <vx-card class="mb-base no-shadow">
      <transition name="fade" mode="out-in">
        <template v-if="showForm">
          <vs-button @click="onCreate">{{ $t("shops.back") }}</vs-button>
        </template>
        <template v-if="!showForm">
          <div class="mb-base">
            <data-table
              class="datatablePromotions"
              :data="tableData"
              :options="tableOptions"
              @create="onCreate"
              @remove="onRemove($event, 'shops.promotion')"
              @update="onUpdate"
              @sort="onSort"
              @search="onSearch"
              @change-page="onTablePageChange"
              @per-page-change="onPerPageChange"
            />
          </div>
        </template>
      </transition>

      <transition name="fade" mode="out-in">
        <template v-if="showForm || onlyForm">
          <div class="mb-base mt-10">
            <promotion-form
              v-model="formData"
              :onlyForm="onlyForm"
              :dialogProduct="dialogProduct"
              :dialogParticipant="dialogParticipant"
              @submit="onPromotionFormSubmit"
            />
          </div>
        </template>
      </transition>
    </vx-card>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters, mapActions } from "vuex";
import DataTable from "@/modules/Shared/Components/table/DataTable";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";
import PromotionForm from "@/modules/Shops/Pages/forms/PromotionForm.vue";

export default {
  name: "Promotions",

  mixins: [ notifications, table_functions, shopLanguages ],

  components: {
    DataTable,
    PromotionForm,
  },

  props: {
    onlyForm: { type: Boolean, default: false },
  },

  data() {
    return {
      dialogProduct: false,
      dialogParticipant: false,
      formData: {},
      showForm: false,
      exportPayload: {}
    };
  },

  async created() {
    this.formData = this.default()
    if (!this.onlyForm) {
      this.$store.commit("shops/promotions/RESET_RESOURCE");

      this.$vs.loading();
      await Promise.all([
        this.loadData(),
        this.loadParticipants({ page_size: 1000 }),
        this.loadProducts({ page_size: 1000 }),
      ])
        .catch((e) => console.warn(e))
        .finally(() => this.$vs.loading.close());
    }
  },

  computed: {
    ...mapGetters("shops/promotions", {
      promotionsList: "getAll",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
      promotionById: "getById",
    }),

    tableData() {
      return this.promotionsList.map(this.promotionToTableData);
    },

    tableOptions() {
      return {
        actions: {
          create: true,
          update: true,
          delete: true,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
        filters: {
          search: true,
        },
      };
    },
  },
  methods: {
    ...mapActions("shops/promotions", { 
      loadData: "load",
      createData: "create",
      updateData: "update",
      removeData: "remove"
    }),
    ...mapActions("shops/products", { loadProducts: "load" }),
    ...mapActions("shops/participants", { loadParticipants: "load" }),
    ...mapActions("shops/periods", { loadPeriods: "load" }),

    promotionToTableData(promotion) {
      return {
        id: promotion.id,
        active: promotion.active,
        name: promotion.name,
        description: promotion.description,
        promocode: promotion.code,
        type: (promotion.type === "percent") ? "%" : "Amount",
        discount_amount: promotion.amount,
        units: promotion.quantity,
        login_required: promotion.needs_login,
        max_applications_for_user: promotion.max_qty_user,
        valid_from: promotion.from_date,
        valid_to: promotion.to_date,
        created_at: promotion.created_at,
        updated_at: promotion.updated_at,
      };
    },

    async createPromotion(data) {
      try {
        const promotion = await this.createData(data);

        this.showForm = false
        this.formData = Object.assign({}, this.formData, promotion);
        this.notifySuccess(
          i18n.t("shops.save"),
          `${i18n.t('shops.promotion')} ${this.formData.name} ${i18n.t('shops.has_been_created_successfully')}`
        );
      } catch (e) {
        this.showForm = true
        this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.failed_to_create_promotion"));
        console.warn(e);
      }
    },

    async onPromotionFormSubmit(promotion) {
      this.$vs.loading();
      try {
        if (promotion.id === 0) {
          await this.createPromotion(promotion);
        } else {
          await this.updatePromotion(promotion);
        }
      } catch (e) {
        this.notifyError(i18n.t("shops.errors.failed_to_create_promotion"), e);
      } finally {
        this.$vs.loading.close();
      }
    },

    async updatePromotion(promotion) {
      this.$vs.loading();
      try {
        await this.updateData(promotion);

        this.formData = this.default();
        this.showForm = false;

        this.notifySuccess(
          i18n.t("shops.update"),
          `${i18n.t('shops.promotion')} ${promotion.name} ${i18n.t('shops.has_been_updated_successfully')}`
        );
      } catch (error) {
        this.showForm = true
        this.notifyError(i18n.t("shops.error_select"), i18n.t("shops.errors.failed_to_update_promotion"));
      } finally {
        this.$vs.loading.close();
      }
    },

    async onUpdate(promotion) {
      const translationsDefault = this.translationsDefault(['name', 'description'])

      this.formData = Object.assign({}, this.formData, {
        ...this.promotionById(promotion.id),
        translations: { ...translationsDefault, ...this.promotionById(promotion.id).translations }
      });
      
      this.formData.catalog_id = promotion.catalog_id;
      this.showForm = true;
    },

    default() {
      return {
        id: 0,
        active: false,
        translations: this.translationsDefault(['name', 'description']),
        name: "",
        description: "",
        max_qty_user: 1,
        quantity: 999999,
        amount: 0,
        type: "",
        product: { id: "", name: "" },
        participant: { id: "", name: "" }
      }
    }
  }
};
</script>

<style>
.addDialog .vs-dialog {
  max-width: 1000px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 800px;
}

.datatablePromotions .vs-table--header {
  @apply flex;
  @apply flex-wrap;
}

.datatablePromotions .vs-button {
  @apply order-3;
}

.datatablePromotions .con-input-search {
  @apply order-2;
  @apply mr-auto;
  @apply ml-4;
}
</style>
